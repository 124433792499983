document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.header')) {
    const links = document.querySelectorAll('.header__link');
    const burgerBtn = document.getElementById('burger');
    const header = document.querySelector('header');

    links.forEach((link) => {
      // eslint-disable-next-line func-names
      link.addEventListener('click', function (e) {
        e.preventDefault();

        const targetId = this.getAttribute('href').substring(1);
        const targetSection = document.getElementById(targetId);

        window.scrollTo({
          top: targetSection.offsetTop,
          behavior: 'smooth',
        });

        header.classList.remove('open');
      });
    });

    document.addEventListener('click', (e) => {
      if (!header.contains(e.target)) {
        header.classList.remove('open');
      }
    });

    burgerBtn.addEventListener('click', () => {
      header.classList.toggle('open');
    });
  }

  if (document.querySelector('[data-header="component"]')) {
    const windowHeight = window.innerHeight;
    const header = document.querySelector('[data-header="component"]');
    const check = () => {
      if (window.pageYOffset >= (windowHeight * 0.25)) {
        header.classList.add('is-scrolled');
      } else {
        header.classList.remove('is-scrolled');
      }
    };

    document.addEventListener('scroll', () => {
      check();
    });

    check();
  }
});
