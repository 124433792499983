import Cookies from '@components/cookies/scripts.js';
import $ from 'jquery';

import '@components/animations/scripts';

import '@components/header/scripts';
import '@components/modal/scripts';
import '@components/input/scripts';
import '@components/link/scripts';
import '@components/tabs/scripts';
import '@components/gallery/scripts';
import '@components/slider/scripts';

import registerFormValidator from '@components/formValidator/init.js';

const init = () => {
    global.spiks= {};
    new Cookies();
    registerFormValidator();

    global.$ = $;
};

export default init;
